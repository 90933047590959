import { UserCreated } from '../eventTypes';

export default {
    Init: () => ({}),

    ["UserCreated"]:
        /**
         * @param {*} state 
         * @param {UserCreated} event 
         */
        function (state, event) {
            let {
                timestamp,
                payload: {
                    fullname,
                    staffId,
                    designation,
                    bank,
                    sortCode,
                    accountNumber,
                    gradeLevel,
                    yearEmployed,
                    sbu,
                    department,
                    location,
                    email,
                    phone
                }
            } = event;

            return {
                ...state,
                fullname,
                staffId,
                designation,
                bank,
                sortCode,
                accountNumber,
                gradeLevel,
                yearEmployed,
                sbu,
                department,
                location,
                email,
                phone,
                createdAt: new Date(timestamp)
            }
        }
}