import uuid from 'uuid';
import {
    InvestmentCreated,
    InvestmentFunded,
    InvestmentInterestCredited,
    InvestmentInterestWithdrawn,
    InvestmentInterestCashedOut,
} from '../eventTypes'

export default {
    Init: () => ({}),

    ["InvestmentCreated"]:
        /**
         * @param {*} state 
         * @param {InvestmentCreated} event Event type
         */
        function (state, event) {
            let { timestamp, payload: { accountId, name } } = event;
            return {
                ...state,
                createdAt: timestamp,
                cashedOut: false,
                funding: [],
                interests: [],
                totalInterestsWithdrawn: 0
            }
        },

    ["InvestmentFunded"]:
        /**
         * @param {*} state 
         * @param {InvestmentFunded} event 
         */
        function (state, event) {
            let { timestamp, payload: { amount } } = event
            return {
                ...state,
                funding: [...state.funding, { id: timestamp, amount }]
            }
        },

    ["InvestmentInterestCredited"]:
        /**
         * @param {*} state 
         * @param {InvestmentInterestCredited} event 
         */
        function (state, event) {
            let { timestamp, payload: { amount } } = event

            return {
                ...state,
                interests: [...state.interests, { id: timestamp, amount }]
            }
        },

    ["InvestmentInterestWithdrawn"]:
        /**
         * @param {*} state 
         * @param {InvestmentInterestWithdrawn} event 
         */
        function (state, event) {
            let { payload: { amount } } = event;

            return {
                ...state,
                totalInterestsWithdrawn: state.totalInterestsWithdrawn + amount
            }
        },

    ["InvestmentInterestCashedOut"]:
        /**
         * @param {*} state 
         * @param {InvestmentInterestCashedOut} event 
         */
        function (state, event) {
            return {
                ...state,
                cashedOut: !state.cashedOut
            }
        }

}
