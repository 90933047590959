import React from 'react'
import { Navbar, Image } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

const App = ({ staticPath }) => {
  const stylesheetLink = {
    rel: 'stylesheet',
    type: 'text/css',
    href: `${staticPath}/bootstrap.min.css`
  }
  const faviconLink = {
    rel: 'icon',
    type: 'image/png',
    href: `${staticPath}/favicon.ico`
  }
  const links = [stylesheetLink, faviconLink]
  const meta = {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1'
  }

  return (
    <div>
      <div>
        <Helmet title="Digital Forte | Enterprise Cooperative as a service platform!" link={links} meta={[meta]} />
        <Navbar>
          <Navbar.Text>
            <Image src={`${staticPath}/resolve-logo.png`} /> Digital Forte CoopaaS
          </Navbar.Text>

          <Navbar.Collapse>
            <Navbar.Text pullRight>
              <Navbar.Link href="https://facebook.com/resolvejs/">
                <Image src={`${staticPath}/fb-logo.png`} />
              </Navbar.Link>
            </Navbar.Text>

            <Navbar.Text pullRight>
              <Navbar.Link href="https://twitter.com/resolvejs">
                <Image src={`${staticPath}/twitter-logo.png`} />
              </Navbar.Link>
            </Navbar.Text>

            <Navbar.Text pullRight>
              <Navbar.Link href="https://github.com/reimagined/resolve">
                <Image src={`${staticPath}/github-logo.png`} />
              </Navbar.Link>
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <h4 align="center">Enterprise Cooperative Bank as a Service API Platform.</h4>
      <p style={{ textAlign: "center" }}>
        <b>[ CQRS/EventStore Service Endpoints ]</b><br/><br/>
        <b>Version</b>: 1.0.0-alpha<br />
        <b>Mode</b>: Listening....
      </p>
      <p style={{ textAlign: 'center' }}>
        Please see API documentation <a href="https://documenter.getpostman.com/view/895720/SWTK3Ywo?version=latest#intro">here</a>
      </p>
    </div>
  )
}

export default App
