import {
    SavingsAccountCreated,
    SavingsCredited,
    SavingsDebited,
    SavingsFunded,
    SavingsReviewed
} from "../eventTypes";

export default {
    Init: () => ({}),

    ["SavingsAccountCreated"]:
        /**
         * @param {*} state 
         * @param {SavingsAccountCreated} event 
         */
        function (state, event) {
            let { timestamp, payload: { accountId, monthlySavingsAmount } } = event
            return {
                ...state,
                accountId,
                monthlySavingsAmount,
                credits: [],
                debits: [],
                funding: [],
                balance: 0,
                createdAt: timestamp
            }
        },

    ["SavingsCredited"]:
        /**
         * @param {*} state 
         * @param {SavingsCredited} event 
         */
        function (state, event) {
            let { timestamp, payload: { amount, reason } } = event;

            return {
                ...state,
                credits: [...state.credits, { id: timestamp, amount, reason, createdAt: new Date(timestamp) }],
                balance: getBalance(state)
            }
        },

    ["SavingsDebited"]:
        /**
         * @param {*} state 
         * @param {SavingsDebited} event 
         */
        function (state, event) {
            let { timestamp, payload: { amount, reason } } = event

            return {
                ...state,
                debits: [
                    ...state.debits, {
                        id: timestamp,
                        amount,
                        reason,
                        createdAt: new Date(timestamp),
                        isApproved: (reason == "Withdrawal self") ? false : true,
                        isDenied: false,
                        isDisbursed: false
                    }
                ],
                balance: getBalance(state)
            }
        },
    ["SavingsWithdrawalApproved"]:
        function (state, event) {
            let { timestamp, payload: { userId, requestId } } = event;

            let debits = state.debits.map(d => {
                if (d.id == requestId) {
                    d.isApproved = true;
                    d.isDenied = false;
                    d.isDisbursed = false;

                    return d;
                }
                return d;
            })

            return {
                ...state,
                debits,
            }

        },
    ["SavingsWithdrawalDenied"]:
        function (state, event) {
            let { timestamp, payload: { userId, requestId } } = event;

            let debits = state.debits.map(d => {
                if (d.id == requestId) {
                    d.isApproved = false;
                    d.isDenied = true;
                    d.isDisbursed = false;

                    return d;
                }
                return d;
            })

            return {
                ...state,
                debits,
            }
        },
    ["SavingsWithdrawalDisbursed"]:
        function (state, event) {
            let { timestamp, payload: { userId, requestId } } = event;

            let debits = state.debits.map(d => {
                if (d.id == requestId) {
                    d.isApproved = true;
                    d.isDenied = false;
                    d.isDisbursed = true;

                    return d;
                }
                return d;
            })

            return {
                ...state,
                debits,
            }
        },
    ["SavingsFunded"]:
        /**
         * @param {*} state 
         * @param {SavingsFunded} event 
         */
        function (state, event) {
            let { timestamp, payload: { amount } } = event;

            return {
                ...state,
                funding: [...state.funding, { id: timestamp, amount, createdAt: new Date(timestamp) }],
                balance: getBalance(state)
            }
        },

    ["SavingsReviewed"]:
        /**
         * @param {*} state 
         * @param {SavingsReviewed} event 
         */
        function (state, event) {
            let { timestamp, payload: { amount } } = event;
            return {
                ...state,
                monthlySavingsAmount: amount
            }
        }

}

function getBalance(state) {
    return state.credits.map(c => c.amount).reduce((a, b) => a + b, 0)
        - state.debits.map(d => d.amount).reduce((a, b) => a + b, 0)
        + state.funding.map(f => f.amount).reduce((a, b) => a + b, 0);
}
