import {
    LoanRequested,
} from "../eventTypes";

export default {
    Init: () => ({}),

    ["LoanRequested"]:
        /**
         * @param {*} state 
         * @param {LoanRequested} event 
         */
        function (state, event) {
            let { timestamp, payload: { accountId, amount, type, duration, interest } } = event;

            return {
                ...state,
                createdAt: timestamp,
                accountId,
                type,
                amount,
                interest,
                duration,
                repayments: [],
                balance: 0,
                isPending: true,
                isApproved: false,
                isDisbursed: false,
                isDenied: false,
                isRepayed: false,
            };
        },

    ["LoanApproved"]: (state) => ({
        ...state,
        isApproved: true
    }),

    ["LoanDisbursed"]: (state) => ({
        ...state,
        isDisbursed: true
    }),

    ["LoanDenied"]: (state) => ({
        ...state,
        isDenied: true
    }),

    ["LoanReset"]: (state) => ({
        ...state,
        amount: 0,
        repayments: [],
        balance: 0,
        isPending: !state.isPending,
        isApproved: !state.isApproved,
        isDisbursed: !state.isDisbursed,
        isDenied: !state.isDenied,
        isRepayed: !state.isRepayed
    }),

    ["LoanRepayed"]: (state, { id, timestamp, payload: { amount } }) => ({
        ...state,
        repayments: [...state.repayments, { id, amount, createdAt: timestamp }],
        balance: getLoanBalance(state)
    })
}

function getLoanBalance(state) {
    return state.repayments.map(s => s.amount).reduce((a, b) => a + b, 0);
}